import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import SEO from "../components/SEO/index"
import IndexLaboratory from "../components/Sections/IndexLaboratory"
import NavBar from "../components/navbar"

import "./mystyles.scss"

const LabPage = ({ data }) => {
  return (
    <>
      <NavBar />
      <SEO title="Fanomed clinics" description="Fanomed clinics - Стационар" />
      <IndexLaboratory data={data.section} />
    </>
  )
}

export const LabPageQuery = graphql`
  query {
    section: allIndexsectionsYaml {
      edges {
        node {
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default injectIntl(LabPage)
